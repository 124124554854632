import React from "react";
import { Footer as FooterStyle } from "../style/footer";

import { Container } from "../style/grid";
import ChevronRight from "../style/icons/chevronRight";
import theme from "../style/theme";
import { getMndWebUrl } from "../utils/get-mnd-web-url";

const Footer = () => {
    const year = new Date().getFullYear();

    return <FooterStyle>
        <Container>
            <img className={"logo"} src={"/images/footer__kkcg.png"} alt={"KKCG"} />
            <ul>
                <li><a href={`${getMndWebUrl()}osobniudaje`}>
                    <ChevronRight color={theme.colors.professionalGray} />
                    Zpracování osobních údajů</a></li>
                <li><a href={`${getMndWebUrl()}cookies`}>
                    <ChevronRight color={theme.colors.professionalGray} />
                    Cookies</a></li>
            </ul>
            <span className={"copy"}>Copyright © {year}, MND Energie a.s.</span>
        </Container>
    </FooterStyle>;
};

export default Footer;



