import gql from 'graphql-tag';
import documentFragment from "../fragments/document";
import productFragment from "../fragments/product"
import personFragment from "../fragments/person"

export default gql`
    fragment ContractFields on Contract {
        id
        commodity
        selectedPoaType
        situation
        subSituations 
        selectedSubSituation
        pricelistLink
        activeDocumentRequest {
            type
            configuration
            configurationItems {
                category
                isRequired
                documentTitle
                sampleDocuments
            }
            documents {
                ...DocumentFields
            }
            state
            signatureState
            documentsState
            paramsPoa {
                relationToProperty {
                    value
                    isRequired
                }
                originalClientState {
                    value
                    isRequired
                }
            }
            onlinePoa {
                hasData
                oldOwner {
                    ico
                    businessName
                    fullName
                    dateOfBirth
                    address
                }
            }
            nextDocumentRequestId
        }
        activeDocumentRequestPoa {
            documents {
                ...DocumentFields
            }
            signatureState
            onlinePoa {
                hasData
                oldOwner {
                    ico
                    businessName
                    fullName
                    dateOfBirth
                    address
                }
            }
        }
        documents {
            ...DocumentFields
        }
        products {
            ...ProductFields
        }
        person {
            ...PersonFields
        }
    }
    ${documentFragment}
    ${productFragment}
    ${personFragment}
`;
