import React from "react";

import {Wrapper, Container, WhiteBoxError, Inner} from "../style/grid";
import {PageHeaderReplaced} from "../style/pageHeader";
import PhoneIcon from "../style/icons/phone";
import theme from "../style/theme";

const ReplacedConatiner = () => (
    <Wrapper>
        <Container>
            <Inner>
                <WhiteBoxError>
                    <PageHeaderReplaced>
                        <h1>Váš odkaz byl nahrazen novým</h1>
                        <p className="reason">Platnost tohoto odkazu vypršela. Mezitím vám byl zaslán nový odkaz, prosím zkontrolujte svou e-mailovou schránku.</p>
                        <p className="contact">V případě potřeby kontaktujte prosím bezplatnou zákaznickou linku</p>
                        <div>
                          <PhoneIcon color={theme.colors.resourceGreen} height={32} width={32} /><a href="tel:800400500">800 400 500</a>
                        </div>
                    </PageHeaderReplaced>
                </WhiteBoxError>
            </Inner>
        </Container>
    </Wrapper>
);

export default ReplacedConatiner;