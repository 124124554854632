import React from "react";

import {Wrapper, Container, WhiteBoxError, Inner} from "../style/grid";
import {PageHeaderExpired} from "../style/pageHeader";
import PhoneIcon from "../style/icons/phone";
import theme from "../style/theme";

const ExpiredConatiner = () => (
    <Wrapper>
        <Container>
            <Inner>
                <WhiteBoxError>
                    <PageHeaderExpired>
                        <h1>Platnost vašeho odkazu již vypršela</h1>
                        <p className="contact">Pro získání nového odkazu kontaktujte prosím bezplatnou zákaznickou linku</p>
                        <div>
                          <PhoneIcon color={theme.colors.resourceGreen} height={32} width={32} /><a href="tel:800400500">800 400 500</a>
                        </div>
                    </PageHeaderExpired>
                </WhiteBoxError>
            </Inner>
        </Container>
    </Wrapper>
);

export default ExpiredConatiner;