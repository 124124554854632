export const getMndWebUrl = () => {
    if (window.location.hostname === "project-podpis.mnd.cz") {
        return "https://project.mnd.cz/"
    }

    if (window.location.hostname === "stage-podpis.mnd.cz") {
        return "https://stage.mnd.cz/"
    }

    return "https://www.mnd.cz/"
};