import React from "react";

import {Wrapper, Container, WhiteBoxError, Inner} from "../style/grid";
import {PageHeaderError} from "../style/pageHeader";
import PhoneIcon from "../style/icons/phone";
import theme from "../style/theme";

const ErrorContainer = () => {
    return (
        <Wrapper>
            <Container>
                <Inner>
                    <WhiteBoxError>
                        <PageHeaderError>
                            <h1>Chyba při zpracování odkazu</h1>
                            <p className="reason">Odkaz se nám bohužel nepodařilo zpracovat.<br/>Je možné, že se nepropsal celý.</p>
                            <p className="contact">Prosím kontaktujte bezplatnou zákaznickou linku</p>
                            <div>
                                <PhoneIcon color={theme.colors.resourceGreen} height={32} width={32} /><a href="tel:800400500">800 400 500</a>
                            </div>
                        </PageHeaderError>
                    </WhiteBoxError>
                </Inner>
            </Container>
        </Wrapper>
    );
};

export default ErrorContainer;
