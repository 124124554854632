import styled from "styled-components";

export const PageHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h1 {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 10px;
        margin-top: 10px;
        text-align: center;
        color: ${props => props.theme.colors.exploratoryGreen};
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            font-size: 36px;
            margin-bottom: 20px;
            line-height: 140%;
        }
    }
    
    h2 {
         text-align: left;
         color: ${props => props.theme.colors.exploratoryGreen};
         font-size: 20px;
         line-height: 26px;
         margin-top: 20px;
         margin-bottom: 7px;
         
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 0;
        }
    }
    
    p {
        font-size: 15px;
        text-align: center;
        color: ${props => props.theme.colors.expertiseGray};
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            font-size: 18px;
        }
    }
    
    a {
        color: ${props => props.theme.colors.expertiseGray};
        
        @media screen and (min-width: ${props => props.theme.breakpoints.mobile}) {
            font-size: 18px;
        }
        
        &:hover {
            text-decoration: none;
        }
    }
`;

export const PageHeaderErrorCommon = styled(PageHeader)`
    justify-content: center;
    align-items: center;

    h1 {
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 38px;
        margin-top: 0px;
    }

    .reason {
        text-align: center;
        line-height: 24px;
        font-weight: 500;
        font-size: 18px;
        color: ${props => props.theme.colors.expertiseGray};
        margin-bottom: 0px;
    }

    a {
        font-size: 22px;
        font-weight: 800;
        text-decoration: none;
        line-height: 22px;
        color: ${props => props.theme.colors.exploratoryGreen};
        border-bottom: 1px solid ${props => props.theme.colors.exploratoryGreen};
    }

    .contact {
        font-size: 22px;
        font-weight: 800;
        text-align: center;
        margin-top: 38px;
        margin-bottom: 16px;
        color: ${props => props.theme.colors.exploratoryGreen};
    }

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 9px;
    }
`

export const PageHeaderError = styled(PageHeaderErrorCommon)`
    h1 {
        color: ${props => props.theme.colors.errorRed};
    }

    .reason {
        text-align: center;
        line-height: 24px;
    }
`;

export const PageHeaderExpired = styled(PageHeaderErrorCommon)`
    h1 {
        color: ${props => props.theme.colors.expertiseGray};
        margin-bottom: 0px;
    }
`;

export const PageHeaderReplaced = styled(PageHeaderErrorCommon)`
    h1 {
        color: ${props => props.theme.colors.expertiseGray};
    }

    .reason {
        max-width: 520px;
    }
`;
